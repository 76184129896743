import menuEN from './en/menu.json';
import menuRU from './ru/menu.json';

import systemsEn from './en/systems.json';
import enrolmentKeysEn from './en/enrolment-keys.json';
import enrolNewSystemEn from './en/enrol-new-system.json';
import globalEn from './en/global.json';
import accountEn from './en/account.json';
import securityEventsEn from './en/security-events.json';
import tagsEn from './en/tags.json';
import settingsEn from './en/settings.json';
import policiesEn from './en/policies.json';
import landingPageEn from './en/landing-page.json';
import dnsEn from './en/dns.json';
import trustRequirementsEn from './en/trust-requirements.json';

import notificationsEn from './en/notifications.json';

import { resources as resourcesFallback } from '../enclave/index';
import { mergeDeepRight } from 'ramda';

export const resources = mergeDeepRight(resourcesFallback, {
  en: {
    menu: menuEN,
    systems: systemsEn,
    'enrolment-keys': enrolmentKeysEn,
    'enrol-new-system': enrolNewSystemEn,
    global: globalEn,
    account: accountEn,
    'security-events': securityEventsEn,
    tags: tagsEn,
    settings: settingsEn,
    policies: policiesEn,
    'landing-page': landingPageEn,
    notifications: notificationsEn,
    dns: dnsEn,
    'trust-requirements': trustRequirementsEn,
  },
  ru: {
    menu: menuRU,
  },
});
